// JQuery ready (Active Admin does not use Turbolinks):
$(document).ready(function() {
  if ($('body.admin_events').length > 0) {
    $('a.check_partnersync').on('click', function(e) {
      var $this = $(this)
      $this.text('Calling...');
      $.ajax($(this).attr('href'),
             {}
             ).done(function(data) {
               where_to_insert = $('body.admin_events tr.row-publish_in_partnersync div.verification_message');
               if (where_to_insert.length > 0) {
                 message = ''
                 if (data["result"]) {
                   message += 'The Event is in Partnersync'
                 }
                 else {
                   message += 'The Event is <strong>NOT</strong> in Partnersync'
                   if ((typeof data['message'] == 'string') && (data['message'].length > 0)) {
                     message += '<br><br><i>' + data['message'] + '</i>'
                   }
                 }
                 where_to_insert.html(message)
               };
               $this.text('Verify');
              });
      e.preventDefault();
    });
    $('a.publish_partnersync').on('click', function(e) {
      var $this = $(this)
      var initial_text = $this.text();
      $this.text('Calling...');
      $.ajax($(this).attr('href'),
             {}
             ).done(function(data) {
               where_to_insert = $('body.admin_events tr.row-publish_in_partnersync div.verification_message');
               if (where_to_insert.length > 0) {
                 message = ''
                 if (data["result"]) {
                   message += 'The Event Partnersync status has been successfully changed'
                 }
                 else {
                   message += 'The Event Partnersync status has <strong>NOT</strong> been changed'
                   if ((typeof data['message'] == 'string') && (data['message'].length > 0)) {
                     message += '<br><br><i>' + data['message'] + '</i>'
                   }
                 }
                 where_to_insert.html(message)
               };
               $this.text(initial_text);
              });
        e.preventDefault();
    });
  }
});
