import Sortable from 'sortablejs';

$(document).ready(function() {

    let activeRaceSponsors = document.querySelector('#active_race_sponsors > tbody');

    if (activeRaceSponsors) {
        new Sortable(activeRaceSponsors, {
            group: 'race_sponsors',
            animation: 150
        });
    }

    // remove race sponsors button
    $(".remove-race-sponsors-button").on('click', function(event){
        event.target.parentElement.parentElement.remove()
    });

    // add race sponsors button
    $(".add-race-sponsors-button").on('click', function(event){
        const newRow = $(event.target.parentElement.parentElement).clone(true)
        $(activeRaceSponsors).append(newRow)

        event.target.innerHTML = '✓'

        setTimeout(function () {
            event.target.innerHTML = 'Add'
        }, 500)
    });

    $("#save-race_sponsors").on('click', function(event){
        if (event.target.innerText === 'Loading...') { return }

        const updateUrl = $(event.target).data('href')

        const children = activeRaceSponsors.children
        let options = []

        // gets ids and positions
        for(let i = 0; i < children.length; i++){
            let element = children[i]

            let raceMatch = element.className.match(/race_(\d*)/)
            let raceSponsorMatch = element.className.match(/race_sponsor_(\d*)/)

            let raceId = raceMatch ? parseInt(raceMatch[1]) : null
            let raceSponsorId = raceSponsorMatch ? parseInt(raceSponsorMatch[1]) : null

            options.push({ race_id: raceId, race_sponsor_id: raceSponsorId, position: i + 1 })
        }

        // update race sponsors
        event.target.innerText = 'Loading...'

        $.ajax({
            headers : {
                'Accept' : 'application/json',
                'Content-Type' : 'application/json'
            },
            url : updateUrl,
            type : 'PATCH',
            data : JSON.stringify({ race_sponsors: options }),
            success : function() {
                $('.flashes').append("<div class=\"flash flash_notice\">Race sponsors successfully updated.</div>")
                setTimeout(() => {
                    window.location.reload()
                }, 1000)

            },
            error : function(jqXHR, textStatus, errorThrown) {
                event.target.innerText = 'Save'
                alert(errorThrown)
            }
        })
    });
});
