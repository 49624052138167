// JQuery ready (Active Admin does not use Turbolinks):
$(document).ready(function() {
  if ($('body.admin_running_tasks').length > 0) {
    // Butttons that require promt for an additional argument:
    $('a.with_an_argument').on('click', function(e) {
      if (confirm('Are you sure you want to run this task?')) {
        arg_name = $(this).attr('data-arg-name');
        arg_value = prompt(arg_name + " argument (optional):", '')
        if (arg_value != '') {
          olden_href = $(this).attr('href');
          $(this).attr('href', olden_href + '&arg=' + arg_value);
        }
      }
      else {
        e.preventDefault();
      }
    });
    // We'll see if any task has timeout:
    $.get('/tasks_timeouts/', function(data) {
        if (data === true) {
          location.reload();
        }
        else {
          // Reload in 10 minutes (10*60,000 milliseconds):
          setTimeout(function () { location.reload(); }, 600000);
        }
    })
  }
});
