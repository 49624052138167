function do_when_ready_for_jcarousel() {
  if ($('div.jcarousel_wrapper').length > 0) {

    var get_another_image = null;  // Timer to add more images every 1 second

    // Interval function, to get more images via Ajax:
    var getAnotherImage = function () {
      image_id = $('ul.jcarousel-list').data('images').shift();
      ul_element = $('ul.jcarousel-list');
      if ((ul_element.length > 0) && (image_id != undefined) && (image_id > 0)) {
        $.ajax('/tags_image_async/',
               { type: 'POST',
                 dataType: 'json',
                 data: { image: { id: image_id } }
               }
        ).done(function(data) {
            image_id     = Number(data['id']);
            image_link   = data['link'];
            image_source = data['source'];
            image_name   = data['name'];
            if ((image_id > 0) && (image_link.length > 0) && (image_source.length > 0)) {
              // Add a new li element, and reload the carousel:
              li_html = '<li class="jcarousel-item">' +
                          '<a href="' + image_link + '" title="' + image_name + '">' +
                            '<center>'+
                              '<img src="' + image_source + '" alt="' + image_name + '" ' +
                                   'style="max-width:345px;max-height:230px;">' +
                               '<br>' +
                               image_id + '. ' + image_name +
                            '</center>'+
                          '</a>'+
                        '</li>'
              ul_element.find('li.jcarousel-item:last').after(li_html);
              if ($('ul.jcarousel-list').data('images').length > 0) {
                var num_images = $('ul.jcarousel-list li.jcarousel-item').length;
                $('div.jcarousel_wrapper div.loading_notice span.image_number').html(num_images);
              }
              else {
                // Clear loading notice:
                $('div.jcarousel_wrapper div.loading_notice').html('');
              }
              $('.jcarousel').jcarousel('reload');
            }
        });
      }
      else {
        // Stop it:
        clearTimeout(get_another_image);
      }
    }

    // When document ready:
    $(function() {

      // As jCarousel was not able to detect the scroll arrows:
      $('.jcarousel_prev').click(function(e) {
          $('.jcarousel').jcarousel('scroll', '-=1');
          e.preventDefault();
      });
      $('.jcarousel_next').click(function(e) {
          $('.jcarousel').jcarousel('scroll', '+=1');
          e.preventDefault();
      });

      // Initialization:
      $('.jcarousel').jcarousel({ 
        wrap: 'circular'
      });

      // If there are more images, load them, one each 7 seconds
      //   Some waiting time is needed in the case when the thumbnails do not exist yet, and Rails needs
      //   to generate them, which could be very CPU intensive (ImageMagick's mogrify).
      //   In previous tests, it took the server up to 13 seconds to build a new thumbnail, so the safest
      //   interval is around 15 seconds (15000 milliseconds), but we have almost all thumbnails already
      //   created:
      if (($('ul.jcarousel-list').length > 0) && ($('ul.jcarousel-list').data('images').length > 0)) {
        get_another_image = setInterval(getAnotherImage, 7000);
      }
    });
  }
}

// Jquery, as Active Admin does not include Turbolinks:
$(document).ready(do_when_ready_for_jcarousel);
