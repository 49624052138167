// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Rails:
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")

// Select2, from yarn (used to be a gem in the assets pipeline):
require("select2")
require("packs/searchable_select/searchable_select")

// ActiveStorage direct uploads (from Rails' doc):
require("packs/direct_uploads")
// JCarousel:
require("packs/jquery.jcarousel.min")
// Gem active_admin_datetimepicker
// - Dependency, now in yarn:
require("jquery-datetimepicker/build/jquery.datetimepicker.full.min")
// - Gem JS Asset, a bit tweaked:
require("packs/active_admin_datetimepicker/active_admin_datetimepicker_edited.js")

// Delphi:
require("packs/admin_add_to_event_race_list")
require("packs/admin_event_in_partnersync")
require("packs/admin_event_faqs")
require("packs/admin_image_index_thumbs")
require("packs/admin_jcarousel")
require("packs/admin_rake_tasks_timeouts")
require("packs/admin_select_2")
require("packs/obstracle_manager")
require("packs/team_memberships")
require("packs/attachment_manager")
require("packs/race_faq_manager")
require("packs/race_sponsor_manager")
require("packs/event_faq_manager")
require("packs/account_merge")
import "activeadmin_addons"

// Stylesheets:
// import 'active_admin/assets/stylesheets/active_admin'
// SASS variable overrides must be declared before loading up Active Admin's styles.
//
// To view the variables that Active Admin provides, take a look at
// `app/assets/stylesheets/active_admin/mixins/_variables.scss` in the
// Active Admin source.
//
// For example, to change the sidebar width:
// $sidebar-width: 242px;
import "../stylesheets/active_admin";

// Active Admin:
// Overriding any non-variable SASS must be done after the fact.
// For example, to change the default status-tag color:
//
//   .status_tag { background: #6090DB; }
import "@activeadmin/activeadmin";
