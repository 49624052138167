// JQuery ready (Active Admin does not use Turbolinks):
$(document).ready(function() {
  if ($('.has_many_container.event_faqs').length > 0) {
    function hid_unhide_faq_fields(num) {
      switch ($('select#event_event_faqs_attributes_'+num+'_faq_type').val()) {
      case 'text_only':
          $('#event_event_faqs_attributes_'+num+'_answer_pre_en_input').hide();
          $('#event_event_faqs_attributes_'+num+'_answer_url_en_input').hide();
          $('#event_event_faqs_attributes_'+num+'_answer_post_en_input').hide();
          $('#event_event_faqs_attributes_'+num+'_answer_pre_alt_input').hide();
          $('#event_event_faqs_attributes_'+num+'_answer_url_alt_input').hide();
          $('#event_event_faqs_attributes_'+num+'_answer_post_alt_input').hide();
          $('#event_event_faqs_attributes_'+num+'_faq_file_input').hide();
          break;
        case 'attached_file':
          $('#event_event_faqs_attributes_'+num+'_answer_pre_en_input').show();
          $('#event_event_faqs_attributes_'+num+'_answer_url_en_input').hide();
          $('#event_event_faqs_attributes_'+num+'_answer_post_en_input').show();
          $('#event_event_faqs_attributes_'+num+'_answer_pre_alt_input').show();
          $('#event_event_faqs_attributes_'+num+'_answer_url_alt_input').hide();
          $('#event_event_faqs_attributes_'+num+'_answer_post_alt_input').show();
          $('#event_event_faqs_attributes_'+num+'_faq_file_input').show();
          break; 
        case 'link':
          $('#event_event_faqs_attributes_'+num+'_answer_pre_en_input').show();
          $('#event_event_faqs_attributes_'+num+'_answer_url_en_input').show();
          $('#event_event_faqs_attributes_'+num+'_answer_post_en_input').show();
          $('#event_event_faqs_attributes_'+num+'_answer_pre_alt_input').show();
          $('#event_event_faqs_attributes_'+num+'_answer_url_alt_input').show();
          $('#event_event_faqs_attributes_'+num+'_answer_post_alt_input').show();
          $('#event_event_faqs_attributes_'+num+'_faq_file_input').hide();
          break;
      }
      $(this).trigger('change');
    };
    function set_faq_type_listener(num) {
      $('select#event_event_faqs_attributes_'+num+'_faq_type').on('change', function(e) { hid_unhide_faq_fields(num) });
    }
    function last_faqs_index() {
      return $('.has_many_container.event_faqs fieldset.has_many_fields').length - 1;
    }
    function initialize_faq_fields() {
      if (last_faqs_index() >= 0) {
        n = 0;
        $('.has_many_container.event_faqs fieldset.has_many_fields').each(function() {
          hid_unhide_faq_fields(n);
          set_faq_type_listener(n);
          n += 1;
        });
      }
    }
    // Initial setup:
    initialize_faq_fields();
    // If adding more FAQs:
    $('body').on('DOMSubtreeModified', '.has_many_container.event_faqs', function() {
      num = last_faqs_index();
      // Set the change listener:
      set_faq_type_listener(num)
      hid_unhide_faq_fields(num)
    });
  }  
});
