// JQuery ready (Active Admin does not use Turbolinks):
$(document).ready(function() {
  if ($('table#index_table_images td.col a.thumb_image').length > 0) {
    $('table#index_table_images td.col a.thumb_image').each(function() {
      image_html = $(this).attr('data-imgtag');
      $(this).html(image_html);
      $(this).removeAttr('data-imgtag')
    })
  }
});
