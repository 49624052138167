import Sortable from 'sortablejs';

$(document).ready(function() {

    let activeFaqs = document.querySelector('#active_faqs > tbody');

    if (activeFaqs) {
        new Sortable(activeFaqs, {
            group: 'faqs',
            animation: 150
        });
    }

    // remove faq button
    $(".remove-faq-button").on('click', function(event){
        event.target.parentElement.parentElement.remove()
    });

    // add faq button
    $(".add-faq-button").on('click', function(event){
        const newRow = $(event.target.parentElement.parentElement).clone(true)
        $(activeFaqs).append(newRow)

        event.target.innerHTML = '✓'

        setTimeout(function () {
            event.target.innerHTML = 'Add'
        }, 500)
    });

    $("#save-faqs").on('click', function(event){
        if (event.target.innerText === 'Loading...') { return }

        const updateUrl = $(event.target).data('href')

        const children = activeFaqs.children
        let options = []

        // gets ids and positions
        for(let i = 0; i < children.length; i++){
            let element = children[i]

            let raceMatch = element.className.match(/race_(\d*)/)
            let faqMatch = element.className.match(/faq_(\d*)/)

            let raceId = raceMatch ? parseInt(raceMatch[1]) : null
            let faqId = faqMatch ? parseInt(faqMatch[1]) : null

            options.push({ race_id: raceId, faq_id: faqId, position: i + 1 })
        }

        // update faqs
        event.target.innerText = 'Loading...'

        $.ajax({
            headers : {
                'Accept' : 'application/json',
                'Content-Type' : 'application/json'
            },
            url : updateUrl,
            type : 'PATCH',
            data : JSON.stringify({ faqs: options }),
            success : function() {
                $('.flashes').append("<div class=\"flash flash_notice\">Faqs successfully updated.</div>")
                setTimeout(() => {
                    window.location.reload()
                }, 1000)

            },
            error : function(jqXHR, textStatus, errorThrown) {
                event.target.innerText = 'Save'
                alert(errorThrown)
            }
        })
    });
});
