setupDateTimePicker = function(container) {
  var defaults, entries;
  defaults = {
    formatDate: 'y-m-d',
    format: 'Y-m-d H:i',
    allowBlank: true,
    defaultSelect: false,
    validateOnBlur: false
  };
  entries = $(container).find('input.date-time-picker');
  return entries.each(function(index, entry) {
    options = $(entry).data('datepicker-options');
    return $(entry).datetimepicker($.extend(defaults, options));
  });
};

setupDatePicker = function(container) {
  var defaults, entries;
  defaults = {
    formatDate: 'y-m-d',
    format: 'Y-m-d',
    allowBlank: true,
    defaultSelect: false,
    validateOnBlur: false,
    timepicker:false
  }

  entries = $(container).find('input.date-picker');
  return entries.each(function(index, entry) {
    options = $(entry).data('datepicker-options');
    return $(entry).datetimepicker($.extend(defaults, options));
  });
};


$(document).ready(function() {
  setupDateTimePicker($('body'));
  setupDatePicker($('body'));
  return $(document).on('has_many_add:after', '.has_many_container', function(e, fieldset) {
    return setupDateTimePicker(fieldset);
  });
});
