$(document).ready(function() {
  $("#team_membership_submit_action").click(function (e) {
    let errors = []
    if (!document.querySelector('#team_membership_team_id').value) {
      e.preventDefault()
      errors.push('Team required')
    }

    if (!document.querySelector('#team_membership_athlete_user_id').value) {
      e.preventDefault()
      errors.push('Athlete user required')
    }

    if (errors.length > 0) { alert(errors.join(', ')) }
  })
})
