$(document).ready(function() {
  $('.merge_accounts #refresh-preview').on('click', function(event) {
    if (event.target.innerText === 'Loading...') { return }

    const previewUrl = $(event.target).data('preview-url')

    // preview butto
    event.target.innerText = 'Loading...'

    let ids = Array.from($('#from_input').find(':selected')).map((o) => o.value)

    $.ajax({
      headers: {
        'Content-Type' : 'application/json'
      },
      url: previewUrl,
      type: 'POST',
      data: JSON.stringify({ from: ids }),
      success: function(data) {
        $('div#preview').html(data)
      },
      error: function(jqXHR, textStatus, errorThrown) {
        alert(errorThrown)
      },
      complete: function (data) {
        event.target.innerText = 'Refresh preview'
      }
    })
  });
});
