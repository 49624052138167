import Sortable from 'sortablejs';

$(document).ready(function() {
  let disabledObstacles = document.querySelector('#disabled_obstacles > tbody');

  if (disabledObstacles) {
    new Sortable(disabledObstacles, {
      group: {
        name: 'obstacles',
        pull: 'clone',
        put: false
      },
      animation: 150,
      sort: false // To disable sorting: set sort to false
    });
  }

  let activeObstacles = document.querySelector('#active_obstacles > tbody');

  if (activeObstacles) {
    new Sortable(activeObstacles, {
      group: 'obstacles',
      animation: 150
    });
  }

  // remove obstacle button
  $(".remove-obstacle-button").on('click', function(event){
    event.target.parentElement.parentElement.remove()
  });

  // remove obstacle button
  $(".add-obstacle-button").on('click', function(event){
    const newRow = $(event.target.parentElement.parentElement).clone(true)
    $(activeObstacles).append(newRow)

    event.target.innerHTML = '✓'

    setTimeout(function () {
      event.target.innerHTML = 'Add'
    }, 500)
  });

  $("#save-obstacles").on('click', function(event){
    if (event.target.innerText === 'Loading...') { return }

    const updateUrl = $(event.target).data('href')

    const children = activeObstacles.children
    let options = []

    // gets ids and positions
    for(let i = 0; i < children.length; i++){
      let element = children[i]

      let obstacleMatch = element.className.match(/obstacle_(\d*)/)
      let obstacleEventMatch = element.className.match(/obstacle_event_(\d*)/)

      let obstacleId = obstacleMatch ? parseInt(obstacleMatch[1]) : null
      let obstacleEventId = obstacleEventMatch ? parseInt(obstacleEventMatch[1]) : null

      options.push({ obstacle_id: obstacleId, obstacle_event_id: obstacleEventId, position: i + 1 })
    }

    // update obstacles
    event.target.innerText = 'Loading...'

    $.ajax({
      headers : {
        'Accept' : 'application/json',
        'Content-Type' : 'application/json'
      },
      url : updateUrl,
      type : 'PATCH',
      data : JSON.stringify({ obstacles: options }),
      success : function() {
        $('.flashes').append("<div class=\"flash flash_notice\">Obstacles  successfully updated.</div>")
        setTimeout(() => {
          window.location.reload()
        }, 1000)

      },
      error : function(jqXHR, textStatus, errorThrown) {
        event.target.innerText = 'Save'
        alert(errorThrown)
      }
    })
  });
});
