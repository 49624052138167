// JQuery ready (Active Admin does not use Turbolinks):
$(document).ready(function() {
  if ($('.add_input_to_array').length > 0) {
    $('form').on('click', '.add_input_to_array', function(e) {
      column = $(this).attr('data-key');
      number_of_inputs = parseInt($('[id$=number_of_'+column+']').val());
      next_number_of_inputs = (number_of_inputs + 1).toString();
      field_set_html = $('div#'+column).html();
      reg_exp = new RegExp(number_of_inputs, 'g');
      $('div#'+column).html(field_set_html.replace(reg_exp, next_number_of_inputs));
      $(this).parent().parent().before(field_set_html);
      $('[id$=number_of_'+column+']').val(next_number_of_inputs);
      e.preventDefault()
    })
  }  
});
