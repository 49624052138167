import Sortable from 'sortablejs';

$(document).ready(function() {

    let activeAttachments = document.querySelector('#active_attachments > tbody');

    if (activeAttachments) {
        new Sortable(activeAttachments, {
            group: 'attachments',
            animation: 150
        });
    }

    // remove attachment button
    $(".remove-attachment-button").on('click', function(event){
        event.target.parentElement.parentElement.remove()
    });

    // add attachment button
    $(".add-attachment-button").on('click', function(event){
        const newRow = $(event.target.parentElement.parentElement).clone(true)
        $(activeAttachments).append(newRow)

        event.target.innerHTML = '✓'

        setTimeout(function () {
            event.target.innerHTML = 'Add'
        }, 500)
    });

    $("#save-attachments").on('click', function(event){
        if (event.target.innerText === 'Loading...') { return }

        const updateUrl = $(event.target).data('href')

        const children = activeAttachments.children
        let options = []

        // gets ids and positions
        for(let i = 0; i < children.length; i++){
            let element = children[i]

            let venueMatch = element.className.match(/venue_(\d*)/)
            let attachmentMatch = element.className.match(/attachment_(\d*)/)

            let venueId = venueMatch ? parseInt(venueMatch[1]) : null
            let attachmentId = attachmentMatch ? parseInt(attachmentMatch[1]) : null

            options.push({ venue_id: venueId, attachment_id: attachmentId, position: i + 1 })
        }

        // update attachments
        event.target.innerText = 'Loading...'

        $.ajax({
            headers : {
                'Accept' : 'application/json',
                'Content-Type' : 'application/json'
            },
            url : updateUrl,
            type : 'PATCH',
            data : JSON.stringify({ attachments: options }),
            success : function() {
                $('.flashes').append("<div class=\"flash flash_notice\">Gallery successfully updated.</div>")
                setTimeout(() => {
                    window.location.reload()
                }, 1000)

            },
            error : function(jqXHR, textStatus, errorThrown) {
                event.target.innerText = 'Save'
                alert(errorThrown)
            }
        })
    });
});
